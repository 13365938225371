import Alpine from "alpinejs";
import Flickity from "flickity";
import persist from '@alpinejs/persist'

window.Alpine = Alpine;
Alpine.plugin(persist)
Alpine.start();

window.addEventListener('scroll', function() {
    var header = document.querySelector('header');
    var scrollPosition = window.scrollY;

    if (scrollPosition > 60) {
      header.classList.add('bg-white/75', 'backdrop-blur');
    } else {
      header.classList.remove('bg-white/75', 'backdrop-blur');
    }
  });


window.addEventListener('load', function () {
    var form = document.getElementById('configForm');
    var message = document.getElementById('message');
    var success = document.getElementById('success');
    var individualMessage = document.getElementById('individualMessage');

    if(form) {
        var fields = {};
        form.querySelectorAll('[name]').forEach(function (field) {
            fields[field.name] = field;
        });

        // Displays all error messages and adds 'error' classes to the form fields with
        // failed validation.
        var handleError = function (response) {
            var errors = [];
            for (var key in response) {
                if (!response.hasOwnProperty(key)) continue;
                if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
                Array.prototype.push.call(errors, response[key]);
            }
            message.innerHTML = errors.join('<br>');
        }

        var onload = function (e) {
            if (e.target.status === 200) {
                success.innerHTML = 'Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei Ihnen melden'
                form.style.display = 'none';
                individualMessage.style.display = 'none';
            } else {
                handleError(JSON.parse(e.target.response));
            }
        };

        var submit = function (e) {
            e.preventDefault();
            var request = new XMLHttpRequest();
            request.open('POST', e.target.action);
            request.onload = onload;
            request.send(new FormData(e.target));
            // Remove all 'error' classes of a possible previously failed validation.
            for (var key in fields) {
                if (!fields.hasOwnProperty(key)) continue;
                fields[key].classList.remove('error');
            }
        };
        form.addEventListener('submit', submit);
    }

});

const body = document.querySelector('body');
body.addEventListener('cookies:saved', function(event) {
    window.location.reload();
});